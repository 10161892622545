import { Box, Divider, Grid, Paper, Typography } from '@mui/material'
import QRcode from '../assets/images/wechatQR.jpg'
export const WeChatCard = ({ leftText, rightText }) => {
  return (
    <Paper sx={{ marginTop: 5, p: 5 }}>
      <Grid
        container
        columns={16}
        columnGap={2}
        // flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Grid
          xs={16}
          md={6}
          component={Box}
          display={'flex'}
          textAlign={'center'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <Box marginBottom={2}>
            <i
              class="fa-solid fa-mobile-screen-button"
              style={{ fontSize: 55, margin: 5 }}
            ></i>
            <i
              class="fa-solid fa-qrcode"
              style={{ fontSize: 55, margin: 5 }}
            ></i>
          </Box>
          <Typography marginBottom={2}>
            Scan to quick add us on WeChat!
          </Typography>
        </Grid>
        <Divider
          flexItem
          orientation="vertical"
          sx={{ display: { xs: 'none', md: 'flex' } }}
        />
        <Grid
          xs={16}
          md={8}
          component={Box}
          display={'flex'}
          width={'100%'}
          justifyContent={{ xs: 'center', md: 'flex-end' }}
        >
          <img src={QRcode} style={{ width: '100%' }} />
        </Grid>
      </Grid>
    </Paper>
  )
}
