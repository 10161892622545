import { Box, Container, Grid, Typography } from '@mui/material'
import { CardItem } from '../components/CardItem'
import { COLOR } from '../siteStyle'
import { useContext } from 'react'
import { LanguageContext } from '../App'
import { SiteButtonBase } from '../components/SiteButtonBase'
import { Link } from 'react-router-dom'
export const ServicesPage = () => {
  const { siteData } = useContext(LanguageContext)
  return (
    <Box>
      <Container
        maxWidth="md"
        sx={{
          mt: 5,
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '70vh',
          }}
        >
          <Typography
            variant="h4"
            textAlign={'center'}
            fontWeight={700}
            marginBottom={5}
            color={COLOR.accent}
          >
            {siteData.serviceSection.heading}
          </Typography>
          <Typography variant="h5">{siteData.serviceSection.text}</Typography>

          <Grid
            sx={{ marginTop: 1 }}
            container
            columnSpacing={3}
            rowSpacing={6}
            justifyContent={'center'}
          >
            {siteData.serviceSection.serviceItems.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                key={index}
                height={450}
                sx={{
                  display: 'flex',
                  justifyContent: 'stretch',
                  alignItems: 'stretch',
                }}
              >
                <CardItem
                  title={item.title}
                  icon={item.icon}
                  content={item.content}
                />
              </Grid>
            ))}
          </Grid>

          <Box display={'flex'} justifyContent={'center'} mt={5}>
            <SiteButtonBase
              size={'large'}
              sx={{
                mt: 5,
                color: 'black',
                fontWeight: 700,
                fontSize: 20,
                paddingX: 15,
              }}
              component={Link}
              to={'/contact'}
            >
              Contact Us
            </SiteButtonBase>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
