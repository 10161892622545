import { Box } from '@mui/material'

export const SiteContainer = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // minHeight: '70vh',

        marginBottom: { xs: 5, md: 10 },
      }}
    >
      {/* <Box backgroundColor={'white'} width={'100%'}> */}
      {children}
      {/* </Box> */}
    </Box>
  )
}
