import { useContext } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom'
import { COLOR } from '../siteStyle'
import { LanguageContext } from '../App'
import { LanguageFAB } from './LanguageFAB'
import { CHINESE_DATA, ENGLISH_DATA } from '../siteData'
import { Container } from '@mui/material'
import logo from '../assets/images/logo.png'

export default function ButtonAppBar() {
  const { language, handleChangeLanguage } = useContext(LanguageContext)
  const { siteData } = useContext(LanguageContext)

  const NavItem = ({ children, ...rest }) => (
    <Button
      component={Link}
      backgroundColor={COLOR.actionColor}
      sx={{
        fontWeight: 700,
        '&:hover': {
          backgroundColor: 'transparent',
          borderBottom: `5px solid ${COLOR.actionColorDark}`,
        },
        color: COLOR.actionColorDark,
      }}
      {...rest}
    >
      {children}
    </Button>
  )

  return (
    <Box sx={{}}>
      <AppBar
        position="static"
        component={'nav'}
        sx={{ backgroundColor: COLOR.white }}
        elevation={2}
      >
        <Toolbar component={Container} sx={{ display: 'flex', justifyContent: 'space-between'}} >
          <Box component={Link} to={'/'} display={'flex'} alignItems={'center'} sx={{  textDecoration: 'none',}}>
              <img
                src={logo}
                alt="logo"
                style={{
                  height: 50,
                  width: 50,
                  margin: 0,
                  padding: 0,
                }}
              />
              <Typography
              variant="h6"
              noWrap
              // component="p"
              // href="#app-bar-with-responsive-menu"
              sx={{
                ml: 2,
                // display: 'flex',
                // fontFamily: 'monospace',
                fontWeight: 700,
                // letterSpacing: '.3rem',
                color: 'black',
              }}
            >
              {siteData.logoSlogan}
            </Typography>
          </Box>

          <Box display={{ xs: 'none', md: 'flex'}}>
            <Box sx={{ color: COLOR.black, fontWeight: 700, mr: 2 }}>
              <NavItem to={'/about'}>
                {language === 'english'
                  ? ENGLISH_DATA.navigation.about
                  : CHINESE_DATA.navigation.about}
              </NavItem>
              <NavItem to={'/services'}>
                {language === 'english'
                  ? ENGLISH_DATA.navigation.services
                  : CHINESE_DATA.navigation.services}
              </NavItem>
              <NavItem to={'/contact'}>
                {language === 'english'
                  ? ENGLISH_DATA.navigation.contact
                  : CHINESE_DATA.navigation.contact}
              </NavItem>
            </Box>

            {/* FAB BUTTON SHOWN IN NAVBAR ONLY IF SCREEN SIZE IS MEDIUM OR LARGER */}
            <Box display={{ xs: 'none', md: 'block' }}>
              <LanguageFAB />
            </Box>
          </Box>
        </Toolbar>
        <Box component={Container} display={{ xs: 'flex', md: 'none'}} alignItems={'center'} justifyContent={'center'}>
          <Box py={2}>
            <Box sx={{ color: COLOR.black, fontWeight: 700, mr: 2 }}>
              <NavItem to={'/about'}>
                {language === 'english'
                  ? ENGLISH_DATA.navigation.about
                  : CHINESE_DATA.navigation.about}
              </NavItem>
              <NavItem to={'/services'}>
                {language === 'english'
                  ? ENGLISH_DATA.navigation.services
                  : CHINESE_DATA.navigation.services}
              </NavItem>
              <NavItem to={'/contact'}>
                {language === 'english'
                  ? ENGLISH_DATA.navigation.contact
                  : CHINESE_DATA.navigation.contact}
              </NavItem>
            </Box>
          </Box>
          {/* FAB BUTTON SHOWN IN NAVBAR ONLY IF SCREEN SIZE IS MEDIUM OR LARGER */}
          <Box>
              <LanguageFAB />
            </Box>
        </Box>
      </AppBar>
    </Box>
  )
}
