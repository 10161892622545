import { COLOR, LanuageIcon } from '../siteStyle'
import { SiteButtonBase } from './SiteButtonBase'
import { useContext } from 'react'
import { LanguageContext } from '../App'
export const LanguageFAB = () => {
  const { language, handleChangeLanguage } = useContext(LanguageContext)
  return (
    <SiteButtonBase
      variant="text"
      onClick={handleChangeLanguage}
      sx={{
        fontWeight: 700,
        borderRadius: 50,
      }}
      startIcon={LanuageIcon}
    >
      {language === 'english' ? '中文' : 'EN'}
    </SiteButtonBase>
  )
}
