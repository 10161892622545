import LanguageIcon from '@mui/icons-material/Language'
import ArticleIcon from '@mui/icons-material/Article'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import BusinessIcon from '@mui/icons-material/Business'
import PhoneIcon from '@mui/icons-material/Phone'

export const COLOR = {
  white: '#FFFF',
  primary: '#F5F5F3', //beige
  primaryLight: '#5C6B73',
  primaryDark: '#5C6B73',
  actionColor: '#C6A606', //gold
  actionColorDark: '#B29506', //gold
  // accent: '#841D0D', //cinnabar red
  accent: '#0a3579', //logo blue
  secondary: '#1C3738', //dark slate gray
  black: '#000F08',
}

//LOGO Blue:
// hex: #0a3579
// RGB: rgba(10,53,121,255)

// export const COLOR = {
//   white: '#FFFFFF',
//   primary: '#005845',
//   primaryLight: '#3F693C',
//   primaryDark: '#004D40',
//   accent: '#D19E12',
// }

export const LanuageIcon = <LanguageIcon />
export const FinancialStatementIcon = <ArticleIcon fontSize="large" />
export const TaxPreparationIcon = <AccountBalanceIcon fontSize="large" />
export const BusinessConsultingIcon = <BusinessIcon fontSize="large" />
export const ContactPhoneIcon = <PhoneIcon />
