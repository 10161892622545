import React, { useContext } from 'react'
import { Box, Container, Divider, Stack, Typography } from '@mui/material'
import { COLOR } from '../siteStyle'
import { COMMON_SITE_DATA } from '../siteData'
import { AboutPage } from './about'
import { LanguageContext } from '../App'
import { SiteButtonBase } from '../components/SiteButtonBase'
import { ContactPage } from './contact'
import { Link } from 'react-router-dom'

const StackItems = ({ icon }) => (
  <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
    <Box
      sx={{
        borderRadius: 50,
        height: { xs: 60, md: 85 },
        width: { xs: 60, md: 85 },
        backgroundColor: COLOR.accent,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <i
        class={icon}
        style={{
          color: '#ffffff',
          fontSize: 30,
        }}
      ></i>
    </Box>
  </Box>
)
export const Home = () => {
  const { siteData } = useContext(LanguageContext)
  return (
    <div>
      <Box marginBottom={{ xs: 0, md: 50 }}>
        {/* HEADER SECTION */}
        <Box backgroundColor={COLOR.accent} width={'100%'} sx={{ py: 10 }}>
          <Container maxWidth={'sm'}>
            <Typography textAlign="center" fontSize={35} color="white" mb={2}>
              {COMMON_SITE_DATA.headerText}
            </Typography>
            <Typography textAlign="center" fontSize={18} color="white">
              {COMMON_SITE_DATA.headerTextSecondary}
            </Typography>
          </Container>
        </Box>
        {/* STACK SECTION */}
        <Box>
          <Stack
            backgroundColor={COLOR.primary}
            justifyContent={'center'}
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={{ xs: 3, md: 5 }}
            // margin={5}
            paddingY={{ xs: 3, md: 5 }}
            // width={'100%'}
          >
            {siteData.serviceSection.serviceItems.map((item, index) => {
              if (index < 3) {
                return <StackItems text={item.title} icon={item.icon} />
              }
              return
            })}
          </Stack>
        </Box>
        {/* CALL TO ACTION */}
        <Box display={'flex'} justifyContent={'center'} my={3}>
          <SiteButtonBase component={Link} to={'/contact'} sx={{ px: 10 }}>
            Get Started
          </SiteButtonBase>
        </Box>
        <Box>
          <AboutPage doNotShowTitle />
        </Box>
        <Box>
          <ContactPage />
        </Box>
      </Box>
    </div>
  )
}
