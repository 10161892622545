import { COLOR } from '../siteStyle'
import { ENGLISH_DATA } from '../siteData'
import { Button } from '@mui/material'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'

const phoneNum = ENGLISH_DATA.contactSection.contactItems.phone[0].content.split(' ')


export const PhoneFAB = () => (
  <Button
    href={`tel:${phoneNum[0]}`}
    sx={{
      backgroundColor: COLOR.actionColor,
      borderRadius: 15,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 5,
      '&:hover': {
        backgroundColor: COLOR.actionColorDark,
      },
      color: 'black',
    }}
  >
    <LocalPhoneIcon />
  </Button>
)
