import BusinessConsultingImg from './assets/images/businessConsulting.jpg'
import FinancialStatementImg from './assets/images/financialStatement.jpg'
import TaxPreparationImg from './assets/images/taxPrep.jpg'

export const COMMON_SITE_DATA = {
  copyRightText: 'Copyright WTM 2024. All rights reserved.',
  headerImg: '',
  headerText: "WTM ACCOUNTING SERVICES",
  headerTextSecondary: 'ACCOUNTING, TAXATION & SMALL BUSINESS CONSULTANTS',
  missionStatement:
    'Our mission is to provide the highest quality accounting services to our clients, helping them achieve their financial goals and secure their future.',
  certificateImg: '',
  weChatQRCodeImg: '',
  promotionalBannerList: ['10% off prepaid services', 'Free consultation'],
}

export const ENGLISH_DATA = {
  navigation: {
    home: 'Home',
    about: 'About',
    services: 'Services',
    contact: 'Contact',
    promotions: 'Promotions',
  },
  logoSlogan: 'WTM Accounting Services',
  contactSection: {
    heading: 'Contact',
    text: 'Our location is only a 3 minute walk from North Quincy Station.',
    text2: 'Free parking for clients!',
    contactItems: {
      address: {
        title: 'Address',
        street: '350 Hancock St, 2nd FL',
        cityStateZip: 'Quincy MA 02171',
      },
      phone: [{ title: 'Phone', content: '617-860-7676 (Direct)' }, { title: 'Phone', content: '857-264-8635' }],
      email: { title: 'Email', content: 'wtmcpatax@gmail.com' },
      wechat: { title: 'WeChat', content: 'wtmcpatax2' },
      hours: {
        title: 'Hours of operation',
        workDays: 'Monday – Friday',
        workHours: '10am – 6pm',
      },
    },
  },

  aboutText: {
    heading: 'About Us',
    paragraph1:
      "Our core values of integrity, professionalism, and client focus guide everything we do. We are committed to upholding the highest standards of ethical conduct, delivering superior quality in all our services, and always putting our clients' needs first.",
  },
  serviceSection: {
    heading: 'Services',
    text: 'Our firm provides tax preparation, bookkeeping, financial planning, payroll, small business consulting, business entity selection, serving clients across various industries and sectors.',
    serviceItemHeading: 'Professional Accounting Services',
    serviceItems: [
      {
        title: 'Tax Preparation',
        content:
          'Our tax preparation service takes the stress out of tax season for you. Our experienced accountants ensure accurate filings and maximize your refunds, leaving you with peace of mind and more time for what matters most.',
        icon: 'fa-solid fa-calculator',
      },
      {
        title: 'Financial statements',
        content:
          "Our financial statement service provides clear and concise summaries of your financial health. Let our expert accountants organize your numbers, giving you insight into your business's performance and helping you make informed decisions with ease.",
        icon: 'fa-solid fa-file-invoice-dollar',
      },
      {
        title: 'Business Consulting',
        content:
          'Maximize your financial potential with our Business Consulting service. Our expert accountants offer personalized guidance to optimize your operations, streamline processes, and drive growth for your business. Let us help you achieve your financial goals efficiently and effectively.',
        icon: 'fa-solid fa-handshake',
      },
      {
        title: 'Business Entity Selection',
        content:
          'Sole proprietorships, partnerships, LLCs, and corporations all offer differing levels of protection and complication for the business owners. We can help you decide which structure is the right fit for your business, and help you reevaluate that decision as your business grows.',
        icon: 'fa-solid fa-handshake',
      },
      {
        title: 'Bookkeeping',
        content:
          'Bookkeeping involves recording financial transactions, maintaining accurate records, and generating financial statements to track a company’s financial health.',
        icon: 'fa-solid fa-handshake',
      },
      {
        title: 'Payroll Services:',
        content:
          'Payroll services manage employee payments, taxes, and benefits on behalf of businesses, ensuring accuracy and compliance with regulations.',
        icon: 'fa-solid fa-handshake',
      },
    ],
  },
}

export const CHINESE_DATA = {
  navigation: {
    home: '首頁',
    about: '關於我們',
    services: '服務範圍',
    contact: '聯絡我們',
  },
  logoSlogan: 'WTM 簡亦報稅',
  contactSection: {
    heading: '聯絡方式',
    text: '我們離北昆士地鐵站只需步行3分鐘.',
    text2: '客戶免費停車!',
    contactItems: {
      address: {
        title: '地址',
        street: '350 Hancock St, 2nd FL',
        cityStateZip: 'Quincy MA 02171',
      },
      phone: [{ title: '電話', content: '617-860-7676 (Direct)' }, { title: '電話', content: '857-264-8635' }],
      email: { title: '電子郵件', content: 'wtmcpatax@gmail.com' },
      wechat: { title: '微信', content: 'wtmcpatax2' },
      hours: {
        title: '辦公時間',
        workDays: '週 一 至週五',
        workHours: '10am – 6pm',
      },
    },
  },
  aboutText: {
    heading: '关于我们',
    paragraph1:
      '簡亦是⼀家專業的會計師事務所，致⼒於為個⼈和企業客⼾提供全⾯的稅務、會計和財務管理服務。我們的團隊由經驗豐富的會計師和稅務專家組成，擁有深厚的專業知識和技能，以滿⾜客⼾不同的需求和要求',
  },
  serviceSection: {
    heading: '服務範圍',
    text: '我們的服務範圍涵蓋了稅務策劃與申報、會計記帳、財務報表編制、員工薪資、企業咨詢等領域。我們提供全美50各州的稅務。 無論您是個⼈投資者、⼩型企業還是跨國企業，我們都能夠提供專業的指導和⽀持，幫助您解決各種會計和財務管理⽅⾯的問題。',
    serviceItemHeading: '專業會計服務',
    serviceItems: [
      {
        title: '稅務申報',
        content:
          '我们的税务申报服务专为您量身定制。我们的会计团队将帮助您准确填写税表，确保您的税务申报顺利无忧。',
        icon: 'fa-solid fa-calculator',
      },
      {
        title: '會計記帳',
        content:
          '我们的会计服务致力于为您提供准确、可靠的财务记录和报告，让您的业务运营更加顺畅。我们的专业团队将确保您的财务信息得到妥善处理，让您可以更专注于您的业务发展',
        icon: 'fa-solid fa-file-invoice-dollar',
      },
      {
        title: '財務報表編制',
        content:
          '我们提供财务报表准备服务，帮助您准确整理财务数据，以便于审计和管理决策。我们专业的会计团队将确保您的财务报告准确无误。',
        icon: 'fa-solid fa-handshake ',
      },
      {
        title: '企業咨詢',
        content:
          '我们的商业咨询服务专为会计师提供。我们帮助您优化财务策略，提高利润，并为您的企业提供持续增长的支持',
        icon: 'fa-solid fa-handshake ',
      },
      {
        title: '員工薪資',
        content:
          '員工薪資：我們提供全面的員工薪資服務，包括薪資計算、稅務申報、福利管理和準確的記錄保留。我们的服務旨在幫助企業輕鬆管理薪資事務，以及確保遵守所有相關的法規和標準。',
        icon: 'fa-solid fa-handshake ',
      },
      {
        title: '公司成立',
        content:
          '公司成立：独资企业、合伙企业、有限责任公司和公司都为企业主提供不同级别的保护和复杂性。 我们可以帮助您决定哪种结构最适合您的业务，并帮助您随着业务的发展重新评估该决定。',
        icon: 'fa-solid fa-handshake ',
      },
    ],
  },
}
