import React from 'react'

import { Box, Paper } from '@mui/material'

//image is a path
export const ImageBox = ({
  image,
  bgSize,
  height,
  width,
  bgRepeat,
  bgPosition,
}) => {
  return (
    <Box
      height={height ? height : '400px'}
      width={width ? width : '100%'}
      sx={{
        backgroundImage: `url(${image})`,
        backgroundSize: bgSize ? bgSize : 'cover',
        backgroundRepeat: bgRepeat ? bgRepeat : 'no-repeat',
        backgroundPosition: bgPosition ? bgPosition : 'center',
      }}
    ></Box>
  )
}
