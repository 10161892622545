import { Button } from '@mui/material'
import { COLOR } from '../siteStyle'
export const SiteButtonBase = ({ children, ...rest }) => {
  return (
    <Button
      variant="contained"
      full
      width
      {...rest}
      sx={{
        '&.MuiButton-root:hover': { bgcolor: COLOR.actionColorDark },
        backgroundColor: COLOR.actionColor,
        color: COLOR.black,
        ...rest.sx,
        fontWeight: 700,
      }}
    >
      {children}
    </Button>
  )
}
