import React, { useContext } from 'react'
import { Container, Paper, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2' // Grid version 2
import { COLOR } from '../siteStyle'
import { ImageBox } from '../components/ImageBox'

import licenseImg from '../assets/images/license.png'
import license2Img from '../assets/images/license2.jpg'
import { LanguageContext } from '../App'
import { SiteContainer } from '../components/util/SiteContainer'

export const AboutPage = ({ doNotShowTitle }) => {
  const { siteData } = useContext(LanguageContext)
  return (
    <SiteContainer>
      <Container maxWidth="md">
        <Grid
          container
          spacing={5}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          marginTop={2}
        >
          <Grid xs={12}>
            <Typography
              sx={{ display: doNotShowTitle && 'none' }}
              variant="h4"
              textAlign={'center'}
              fontWeight={700}
              color={COLOR.accent}
              marginBottom={5}
            >
              {siteData.aboutText.heading}
            </Typography>
            <Paper
              elevation={doNotShowTitle ? 1 : 0}
              sx={{
                padding: 5,
                backgroundColor: doNotShowTitle ? 'white' : 'transparent',
              }}
            >
              <Typography variant="h5" textAlign={'center'}>
                {siteData.aboutText.paragraph1}
              </Typography>
            </Paper>
          </Grid>
          <Grid xs={12} md={6}>
            <ImageBox image={licenseImg} bgSize={'contain'} />
          </Grid>
          <Grid xs={12} md={6}>
            <ImageBox image={license2Img} bgSize={'contain'} />
          </Grid>
        </Grid>
      </Container>
    </SiteContainer>
  )
}
