import { Box, CardContent, Paper, Typography } from '@mui/material'
import { COLOR } from '../siteStyle'

export const CardItem = ({ title, titleColor, content }) => {
  return (
    <Paper elevation={2} sx={{ minHeight: '100%' }}>
      <Box
        sx={{
          paddingY: 5,
          backgroundColor: COLOR.accent,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}
      >
        <Typography
          variant="h5"
          component="div"
          color={titleColor ? titleColor : COLOR.white}
          textAlign={'center'}
        >
          {title}
        </Typography>
      </Box>
      <CardContent>
        <Typography variant="body1">{content}</Typography>
      </CardContent>
    </Paper>
  )
}
